import React from 'react'
import Helmet from 'react-helmet'
import useSiteMetadata from '@blocks-helpers/useSiteMetadata'

const Seo = ({
  title,
  description,
  excerpt,
  meta,
  keywords,
  author,
  thumbnail,
  siteUrl
}) => {
  const site = useSiteMetadata()

  const social = (author && author.social) || site.social || []
  const twitter =
    social.find(s => s.name && s.name.toLowerCase() === 'twitter') || {}

  description = excerpt || description || site.description

  thumbnail = thumbnail && thumbnail.hero && thumbnail.hero.src
  const thumbnailUrl =
    thumbnail &&
    (thumbnail.startsWith('//')
      ? thumbnail
      : siteUrl && `${siteUrl}${thumbnail}`)

  /**
   * Meta Tags
   */

  const metaTags = [
    { itemprop: 'name', content: title || site.title },
    { itemprop: 'description', content: description },
    { name: 'description', content: description },

    { property: 'og:url', content: 'https://flokisolutions.com/' },

    { property: 'og:title', content: title || site.title },
    { property: 'og:description', content: description },
    { property: 'og:type', content: 'website' },
    { property: 'og:site_name', content: site.name },
    { property: 'og:image', content: '/favicon.png' }
  ]

  if (keywords && keywords.length > 0) {
    metaTags.push({ name: 'keywords', content: keywords.join(', ') })
  }

  if (meta) {
    metaTags.push(meta)
  }

  metaTags.push({ name: 'initial-scale', content: '1.0' })
  var map
  function initMap() {
    map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: -34.397, lng: 150.644 },
      zoom: 8
    })
  }
  return (
    <Helmet
      htmlAttributes={{
        lang: 'en'
      }}
      title={site.title}
      //  titleTemplate={`%s | ${site.title}`}
      meta={metaTags}
      link={[
        {
          rel: 'shortcut icon',
          type: 'image/png',
          href: `/favicon.png`
        }
      ]}
    >
      <meta
        name='google-site-verification'
        content='jvulVjeMbr8Qb07aPAbFxWgBuwOl_Q2A9EvJoemO1co'
      />
      <meta name='title' content={site.title} />
      <meta name='description' content={site.description} />
      <meta name='og:url' content={'https://flokisolutions.com/'} />
      <meta name='og:title' content={title || site.title} />
      <meta name='og:description' content={description} />
      <meta name='og:type' content={'webiste'} />
      <meta name='og:type' content={site.name} />
      <meta name='og:image' content={'/favicon.png'} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:domain' content='flokisolutions.com' />
      <meta name='twitter:url' content='https://flokisolutions.com/' />
      <meta name='twitter:image' content={'/favicon.png'} />
      <meta name='twitter:site' content={site.name} />
      <meta name='twitter:title' content={site.name} />
      <meta name='twitter:description' content={site.description} />
      <meta name='twitter:creator' content={'flokisolutions.com'} />

      <script type='application/ld+json'>
        {`{"@context": "http:\/\/schema.org",
     "@type": "LocalBusiness",
     "url": "https://flokisolutions.com/",
     "name": "Floki Solutions",
     "description":"launched in 2017 and being among the first comers to the e-grocery market has allowed us to optimize our e-commerce skills in technology, operations, and digital marketing. Following 3 years of deep learning, and based on all the data collected throughout our journey, we reached a point in which we could clearly identify the requirements needed to make it through. ",
  
  "logo":"https://flokisolutions.com/static/01a575c40f24ade3aadac6a5586c7259/b7702/Floki_Logo.webp",
     "image": [
     {
     "@type": "imageObject",
     "url":"https://flokisolutions.com/static/4766fef6d8414584cd8fa852b64eb29b/774a3/hero.webp"
      }
      ],
     "email": "hello@flokisolutions.com",
     "telephone": "+971 50 826 2903",
     "address": {
         "@type": "PostalAddress",
         "streetAddress": "Dubai Silicon Oasis",
         "addressLocality": "Dubai Digital Park,Building A5",
         "postalCode": "00000",
         "addressCountry": "Dubai"
     },
     "geo": {
         "@type": "GeoCoordinates",
         "latitude": "25.1337825",
         "longitude":"55.3711206"
     },
     "openingHours": [
         "Mon- Fri: 00–00am:00-00pm"      
  
  
  ],   
     "priceRange": "$$"
     
}`}
      </script>
      <script type='application/ld+json'>
        {`
  {"@context": "http://schema.org",
  "@type": "Organization",
  "name": "Floki Solutions",
  "url": "https://flokisolutions.com/",
  "logo":"https://flokisolutions.com/static/01a575c40f24ade3aadac6a5586c7259/b7702/Floki_Logo.webp",
  "contactPoint": [
      {
          "@type": "ContactPoint",
          "telephone": "+971 50 826 2903",
          "contactType": "customer service",
          "areaServed": [
              "Dubai"
          ],
          "availableLanguage": [
              "English"
       ]
     }
 ]
}`}
      </script>
      <script type='text/javascript'>
        {`(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "hpux6c4sf3");`}
      </script>
      <script
        type='text/javascript'
        id='hs-script-loader'
        async
        defer
        src='//js-na1.hs-scripts.com/7820632.js'
      ></script>
    </Helmet>
  )
}

export default Seo
